<template>
  <div v-if="!!currentUser" class="cvm-profile-menu mks-full-height">
    <Icon
      value="burger"
      class="cvm-profile-menu__burger"
      :data-test="dataTests.BURGER"
    />
    <q-menu
      @update:model-value="handleUpdateMenu"
      class="cvm-profile-menu__menu"
      :data-test="dataTests.MENU"
      :offset="[30, 8]"
    >
      <q-list>
        <q-item class="cvm-profile-menu__item mks-flex mks-col">
          <div class="cvm-profile-menu__info mks-flex mks-row">
            <Avatar size="small" />
            <p
              class="cvm-profile-menu__info-name"
              :data-test="dataTests.USER_NAME"
            >
              {{ getFullNameUser(currentUser) }}
            </p>
          </div>
          <Button
            @click="signOut"
            view="text"
            class="cvm-profile-menu__exit mks-flex mks-row"
            label="Выйти"
          >
            <template #before>
              <Icon class="cvm-profile-menu__exit-icon" value="sign-out" />
            </template>
          </Button>
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue';
import { logout } from '~packages/keycloak';

import { useMe } from '@/store/queries/users';
import { getFullNameUser } from '@bi-core/helpers/getFullNameUser';
import { Avatar } from '@clearview/ui';
import { Button, Icon } from '@mkskom/ui';

import { dataTests } from './tests/constants';

interface Emits {
  (e: 'updateMenuState', value: boolean): void;
}
const emit = defineEmits<Emits>();

const { data: currentUser } = useMe();

const handleUpdateMenu = value => {
  emit('updateMenuState', value);
};

const signOut = async () => {
  await logout();
};
</script>
<style scoped lang="scss">
@import './CvmProfileMenu';
</style>
