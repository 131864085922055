import { SearchParams } from '@/types/api';

export function getURLSearchParamsWithArray(searchParams: SearchParams) {
  const params = new URLSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-for-in-array
  for (const key in searchParams) {
    const val = searchParams[key];
    if (Array.isArray(val)) {
      val.forEach(el => {
        params.append(key, el);
      });
    } else {
      params.append(key, val);
    }
  }
  return params;
}
