<template>
  <div class="mkskom-bi" ref="mainElementRef">
    <!-- <ReloadPrompt /> -->
    <CvIconsSprite style="position: absolute; width: 0; height: 0" />
    <MksIconsSprite style="position: absolute; width: 0; height: 0" />

    <div class="mkskom-bi__content">
      <slot>
        <component :is="layout?.component" v-bind="layout?.props"></component>
      </slot>
      <GlobalLoader />
      <portal-target name="bi-modal" multiple></portal-target>
    </div>
  </div>
  <VueQueryDevtools />
</template>

<script>
import { useMeta } from 'quasar';
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { BIAnalyticPlugin } from '~packages/bi-analytic';

import { usePlatform } from '@/composables/common';
import { useDevice } from '@/store/bi-store/device';
import CvIconsSprite from '@clearview/icons/cv-icons.svg?component';
import { ICONS_PREFIXES, useIconMap } from '@clearview/ui';
// TODO появляется белый экран после обновления
// import ReloadPrompt from '@components/ReloadPrompt';
import { GlobalLoader } from '@components/Loader';
import MksIconsSprite from '@mkskom/icons/mkskom-icons.svg?component';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import { useResizeObserver } from '@vueuse/core';

export default defineComponent({
  name: 'Main',
  components: {
    CvIconsSprite,
    MksIconsSprite,
    GlobalLoader,
    VueQueryDevtools,
    // ReloadPrompt,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      return route.meta?.layout;
    });

    const meta = BIAnalyticPlugin.meta();
    const mainElementRef = ref();
    const { isMobile } = usePlatform();
    const { setDevice } = useDevice();
    useMeta(() => ({ ...meta }));

    useResizeObserver(mainElementRef, entries => {
      const entry = entries[0];
      const { width } = entry.contentRect;
      setDevice(isMobile, width);
    });

    const { setIconMap } = useIconMap();
    setIconMap(ICONS_PREFIXES);

    return { layout, mainElementRef };
  },
});
</script>
