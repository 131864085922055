import type {
  ColumnModelDictionaryDto,
  ColumnModelShowcaseDto,
  ColumnModelViewDto,
  ModelDictionaryResponseDto,
  ModelShowcaseResponseDto,
  ModelViewResponseDto,
  TableSchema,
} from '@/api/generated';

export type TModelRowsData = Array<string | number>;

export type TShowcaseColumn = ColumnModelShowcaseDto;
export type TDictionaryColumn = ColumnModelDictionaryDto;
export type TModelViewColumn = ColumnModelViewDto;

export type TModelColumnData =
  | TShowcaseColumn
  | TDictionaryColumn
  | TModelViewColumn;

export enum EDataType {
  Date = 'DATE',
  Numeric = 'NUMERIC',
  String = 'STRING',
  Boolean = 'BOOLEAN',
}

export enum ETypeColumn {
  Dimension = 'DIMENSION',
  Value = 'VALUE',
  NotValid = 'NOTVALID',
  Formula = 'FORMULA',
}

export const enum ETypeModel {
  Showcase = 'SHOWCASE',
  Dictionary = 'DICTIONARY',
  View = 'VIEW',
  Kafka = 'KAFKA',
  Postgres = 'POSTGRES',
  Clickhouse = 'CLICKHOUSE',
}

export const enum EOptionsModel {
  Schema = 'schema',
  Configure = 'configure',
}

export interface IModelSchemaColumn {
  dataType: EDataType;
  human_name: string;
  name: string;
  source: string;
  sourceId: string;
  sourceIndex: number;
  type: ETypeColumn;
  nullable: boolean;
  additional_fields?: IModelSchemaColumn[];
  columnType?: unknown;
}

export type ModelSchemaProcessColumn = Omit<
  IModelSchemaColumn,
  'human_name' | 'additional_fields'
> & {
  humanName: string;
  additionalFields?: ModelSchemaProcessColumn[];
  level: number;
};

export interface ModelSchemaTableColumn {
  description: string;
  name: string;
  _name: string;
  type: ETypeColumn;
  dataType: EDataType;
  source: string;
  level: number;
}

export interface ModelObject {
  id: string;
  name: string;
  modelId: string;
  showcaseInfo: {
    columns: ModelSchemaProcessColumn[];
    table_name: string;
  };
  databases: Array<{
    name: string;
    tables: Array<{
      name: string;
      _name: string;
      attributes: ModelSchemaTableColumn[];
    }>;
  }>;
}

export interface ModelField {
  id: string;
  name: string;
  type: ETypeColumn;
  dataType: EDataType;
  sourceId?: string;
  columnName?: string;
  nullable?: boolean;
  sourceIndex?: number;
  columnType?: unknown;
  level: number;
}

export interface ModelSettings {
  id: string;
  name: string;
  modelId: string;
  database: string;
  table: string;
  fields: ModelField[];
  schema: ModelObject;
}

export type TModelSchema = Omit<TableSchema, 'columns'> & {
  columns: IModelSchemaColumn[];
};

export type TModelShowcaseData = Omit<ModelShowcaseResponseDto, 'type'> & {
  type: ETypeModel.Showcase;
};
export type TDictionaryData = Omit<ModelDictionaryResponseDto, 'type'> & {
  type: ETypeModel.Dictionary;
};
export type TModelViewData = Omit<ModelViewResponseDto, 'type'> & {
  type: ETypeModel.View;
};
export type TModelData = TModelShowcaseData | TDictionaryData | TModelViewData;
