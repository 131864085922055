import 'animate.css';
import PortalVue from 'portal-vue';
import { createApp } from 'vue';
import VueGridLayout from 'vue-grid-layout';
import { BIAnalyticPlugin } from '~packages/bi-analytic';
import { logger } from '~packages/bi-logger';
import { createKeycloak } from '~packages/keycloak';

import { BaseApiService } from '@/api/client';
import Main from '@/app/Main.vue';
import router, { PiniaVuePlugin, pinia } from '@/app/router/index';
import '@/assets/styles';
import { install } from '@/bootstrap/api';
import { authInterceptor } from '@/interceptors';
import {
  LanguagePlugin,
  QuasarPlugin,
  VueQueryPlugin,
  initSentry,
  quasarPluginConfig,
  vueQueryPluginOptions,
} from '@/plugins';
import '@/plugins/vee-validate';
import '@/vendor/katex';
import getConstants from '@bi-core/constants/env';
import { mount } from '@components/Loader';
import BiTransition from '@components/Transition';

const showApp = async () => {
  const constants = getConstants();
  const { getVars: getEnvVars, setDynamicEnv } = constants;

  await setDynamicEnv('/env.json');
  install();

  const envVars = getEnvVars();

  const app = createApp(Main);
  app.use(QuasarPlugin, quasarPluginConfig);
  app.use(VueQueryPlugin, vueQueryPluginOptions);
  app.use(PortalVue);
  app.use(logger);
  app.use(BIAnalyticPlugin);
  app.use(VueGridLayout);
  app.use(LanguagePlugin);
  app.component('BiTransition', BiTransition);
  app.use(createKeycloak(), {
    checkLoginIframe: false,
    clientId: envVars.VITE_KC_CLIENT_ID,
    clientSecret: envVars.VITE_KC_CLIENT_SECRET,
    onLoad: 'login-required', // Перенаправлять неавторизованных пользователей на страницу входа,
    realm: envVars.VITE_KC_REALM,
    url: envVars.VITE_KC_URL,
  });

  app.use(PiniaVuePlugin);
  app.use(pinia);
  app.use(router);

  initSentry(app, { router });

  await mount();
  app.mount('#q-app');

  BaseApiService.register(authInterceptor());
};

showApp().catch(e => console.error(e));
