<template>
  <router-link
    @click="updateView($route.path)"
    class="sidebar__item column items-center"
    :to="to"
  >
    <Icon class="sidebar__item_icon" :value="icon.name" :prefix="icon.prefix" />
    <div class="sidebar__item_label">
      {{ label }}
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import { IconWithPrefix } from '@clearview/ui';
import { Icon } from '@mkskom/ui';

export interface SidebarItemProps {
  id: string;
  icon: IconWithPrefix;
  label: string;
  to: RouteLocationRaw;
}

const props = withDefaults(defineProps<SidebarItemProps>(), {
  id: '',
  label: '',
  to: '',
});

const updateView = path => {
  path = path.split('/').filter(el => el);
  path[length - 1] = props.id;
  path = path.join('/');
  path = '/' + path;
};
</script>

<style lang="scss">
@import './SidebarItem';
</style>
