<template>
  <div v-if="!!currentUser" class="profile-box">
    <Avatar :data-testid="testIds.AVATAR" />
    <q-menu class="profile-menu" :offset="[0, 8]">
      <q-list>
        <q-item
          class="profile-menu__item profile-menu__item_user-info profile-card row no-wrap items-center"
        >
          <Avatar class="profile-card__avatar" />
          <div class="profile-card__info column">
            <p class="profile-card__name">
              {{ getFullNameUser(currentUser) }}
            </p>
            <p class="profile-card__email">{{ currentUser.email }}</p>
          </div>
        </q-item>
        <q-item
          class="profile-menu__item profile-menu__item_edit row no-wrap items-center"
          clickable
        >
          <Icon class="profile-menu__item-icon" value="settings" />
          Редактировать профиль
        </q-item>
        <q-separator class="profile-menu__separator" />
        <q-item
          @click="signOut"
          class="profile-menu__item profile-menu__item_sign-out row no-wrap items-center"
          clickable
        >
          <Icon class="profile-menu__item-icon" value="sign-out" />
          Выход
        </q-item>
        <q-separator class="profile-menu__separator" />
        <Legal />
      </q-list>
    </q-menu>
  </div>
</template>

<script setup lang="ts">
import { logout } from '~packages/keycloak';

import { getFullNameUser } from '@/bi-core/helpers/getFullNameUser';
import { useMe } from '@/store/queries/users';
import { Avatar } from '@clearview/ui';
import { Icon } from '@mkskom/ui';

import { Legal } from '../Legal';
import { testIds } from './tests/constants';

const { data: currentUser } = useMe();

const signOut = async () => {
  await logout();
};
</script>
<style scoped lang="scss">
@import './ProfileMenu';
</style>
