<template>
  <div class="sidebar">
    <div class="sidebar__content column">
      <div class="column">
        <SidebarItem v-for="item in mainItems" v-bind="item" :key="item.id" />
      </div>
      <div class="sidebar__footer column">
        <SidebarItem v-for="item in footerItems" v-bind="item" :key="item.id" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';

import {
  CONNECTIONS_NAME,
  DASHBOARD_PAGE_NAME,
  SHOWCASES_NAME,
  TEAM_NAME,
  WIDGETS_CATALOG_NAME,
} from '@bi-router/routes.json';

import { SidebarItem, SidebarItemProps } from './SidebarItem';

export interface SidebarProps {
  projectId: string;
}

const props = withDefaults(defineProps<SidebarProps>(), {
  projectId: '',
});

const mainItems: Array<SidebarItemProps> = [
  {
    id: 'connection',
    icon: {
      name: 'connection',
      prefix: 'cv-icons',
    },
    label: 'Подключения',
    to: {
      name: CONNECTIONS_NAME,
      params: { projectId: props.projectId },
    },
  },
  {
    id: 'showcase',
    icon: {
      name: 'showcase',
      prefix: 'cv-icons',
    },
    label: 'Витрины',
    to: {
      name: SHOWCASES_NAME,
      params: { projectId: props.projectId },
    },
  },
  {
    id: 'widgets',
    icon: {
      name: 'widgets',
      prefix: 'cv-icons',
    },
    label: 'Виджеты',
    to: {
      name: WIDGETS_CATALOG_NAME,
      params: { projectId: props.projectId },
    },
  },
  {
    id: 'dashboards',
    icon: {
      name: 'dashboards',
      prefix: 'cv-icons',
    },
    label: 'Дашборды',
    to: {
      name: DASHBOARD_PAGE_NAME,
      params: { projectId: props.projectId },
    },
  },
];

const footerItems: Array<SidebarItemProps> = [
  {
    id: 'team',
    icon: {
      name: 'team',
    },
    label: 'Пользователи',
    to: {
      name: TEAM_NAME,
      params: { projectId: props.projectId },
    },
  },
];
</script>

<style lang="scss" scoped>
@import './Sidebar';
</style>
