import { EDashboardFilterComparison } from '@/types/dashboard';
import { EDataType } from '@/types/model';
import { CV_ICON_PREFIX, IconWithPrefix } from '@clearview/ui';

export enum EValueTypes {
  Input = 'input',
  Select = 'select',
  Disabled = 'disabled',
  DatePicker = 'datePicker',
  Union = 'union',
}
export interface Comparison {
  comparison?: EDashboardFilterComparison;
  name?: string;
  value: EValueTypes;
  icon?: IconWithPrefix;
  case?: EDataType[];
}
export const comparisonSettings: Comparison[] = [
  {
    comparison: EDashboardFilterComparison.Contains,
    name: 'Содержит',
    value: EValueTypes.Input,
    icon: { name: 'contains', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotContains,
    name: 'Не содержит',
    value: EValueTypes.Input,
    icon: { name: 'not-contains', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.StartWith,
    name: 'Начинается с',
    value: EValueTypes.Input,
    icon: { name: 'begin-with', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotStartWith,
    name: 'Не начинается с',
    value: EValueTypes.Input,
    icon: { name: 'not-begin-with', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.EndWith,
    name: 'Заканчивается на',
    value: EValueTypes.Input,
    icon: { name: 'end-with', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotEndWith,
    name: 'Не заканчивается на',
    value: EValueTypes.Input,
    icon: { name: 'not-end-with', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.Less,
    name: 'Меньше',
    value: EValueTypes.DatePicker,
    icon: { name: 'less', prefix: CV_ICON_PREFIX },
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.LessThanEqual,
    name: 'Меньше или равно',
    value: EValueTypes.DatePicker,
    icon: { name: 'less-or-equal', prefix: CV_ICON_PREFIX },
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Greater,
    name: 'Больше',
    value: EValueTypes.DatePicker,
    icon: { name: 'greater', prefix: CV_ICON_PREFIX },
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.GreaterThanEqual,
    name: 'Больше или равно',
    value: EValueTypes.DatePicker,
    icon: { name: 'greater-or-equal', prefix: CV_ICON_PREFIX },
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Less,
    name: 'Меньше',
    value: EValueTypes.Input,
    icon: { name: 'less', prefix: CV_ICON_PREFIX },
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.LessThanEqual,
    name: 'Меньше или равно',
    value: EValueTypes.Input,
    icon: { name: 'less-or-equal', prefix: CV_ICON_PREFIX },
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.Greater,
    name: 'Больше',
    value: EValueTypes.Input,
    icon: { name: 'greater', prefix: CV_ICON_PREFIX },
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.GreaterThanEqual,
    name: 'Больше или равно',
    value: EValueTypes.Input,
    icon: { name: 'greater-or-equal', prefix: CV_ICON_PREFIX },
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.Eq,
    name: 'Равно',
    value: EValueTypes.Input,
    icon: { name: 'equal' },
    case: [EDataType.String, EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.NotEq,
    name: 'Не равно',
    value: EValueTypes.Input,
    icon: { name: 'not-equal', prefix: CV_ICON_PREFIX },
    case: [EDataType.String, EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.DateEq,
    name: 'Равно',
    value: EValueTypes.DatePicker,
    icon: { name: 'equal' },
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.DateNotEq,
    name: 'Не равно',
    value: EValueTypes.DatePicker,
    icon: { name: 'not-equal', prefix: CV_ICON_PREFIX },
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Includes,
    name: 'В списке',
    value: EValueTypes.Select,
    icon: { name: 'in-the-list', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotIncludes,
    name: 'Не в списке',
    value: EValueTypes.Select,
    icon: { name: 'not-in-the-list', prefix: CV_ICON_PREFIX },
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.IsEmpty,
    name: 'Является пустым',
    value: EValueTypes.Disabled,
    icon: { name: 'empty', prefix: CV_ICON_PREFIX },
    case: [EDataType.String, EDataType.Numeric, EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.IsNotEmpty,
    name: 'Не является пустым',
    value: EValueTypes.Disabled,
    icon: { name: 'not-empty', prefix: CV_ICON_PREFIX },
    case: [EDataType.String, EDataType.Numeric, EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Union,
    name: 'Диапазон',
    value: EValueTypes.DatePicker,
    icon: { name: 'range', prefix: CV_ICON_PREFIX },
    case: [EDataType.Date, EDataType.Numeric],
  },
];
