import {
  EDashboardElementsType,
  EDashboardItemsType,
  IDraggbleOptions,
  IResizeOptions,
} from '@/types/dashboard';
import { EDataType } from '@/types/model';
import { CV_ICON_PREFIX, IconWithPrefix } from '@clearview/ui';
import type { ConfirmFormButton } from '@components/ConfirmForm/types';

export const defaultPage = {
  name: 'Страница 1',
  colsCount: 26,
  rowsHeight: 45,
  isVisible: true,
};

export const dialogDeleteButtons: ConfirmFormButton[] = [
  {
    name: 'Отменить',
    id: 'cancel',
    view: 'outlined',
  },
  {
    name: 'Удалить',
    id: 'delete',
    view: 'filled',
  },
];

export const resizeOptions: IResizeOptions = {
  autoScroll: {
    container: null,
    margin: 50,
    distance: 5,
    interval: 10,
    speed: 400,
  },
};

export const dragOptions: IDraggbleOptions = {
  autoScroll: {
    container: null,
    margin: 50,
    distance: 5,
    interval: 10,
    speed: 400,
  },
};

export const delays = {
  INPUT_SAVE_HISTORY: 800,
};

export const fabButtons = [
  {
    id: EDashboardItemsType.GROUP,
    label: 'Группа виджетов',
    icon: { name: 'group', prefix: CV_ICON_PREFIX },
  },
  {
    id: EDashboardElementsType.Button,
    label: 'Виджет-кнопка',
    icon: { name: 'object-button', prefix: CV_ICON_PREFIX },
  },
  {
    id: EDashboardElementsType.Text,
    label: 'Текст',
    icon: { name: 'text', prefix: CV_ICON_PREFIX },
  },
  {
    id: EDashboardElementsType.Image,
    label: 'Изображение',
    icon: { name: 'image' },
  },
  {
    id: EDashboardElementsType.Filter,
    label: 'Виджет-фильтр',
    icon: { name: 'filter-widget', prefix: CV_ICON_PREFIX },
  },
  {
    id: EDashboardItemsType.WIDGET,
    label: 'Виджет',
    icon: { name: 'widgets', prefix: CV_ICON_PREFIX },
  },
];

export const dialogSaveButtons: ConfirmFormButton[] = [
  {
    name: 'Не сохранять',
    id: 'cancel',
    view: 'outlined',
  },
  {
    name: 'Сохранить',
    id: 'accept',
    view: 'filled',
  },
];

export const dialogCreateShowcaseButtons: ConfirmFormButton[] = [
  { view: 'outlined', name: 'Позже', id: 'cancel' },
  { view: 'filled', name: 'Создать витрину', id: 'accept' },
];

export const dataTests = {
  CONTENT: 'dashboard__content',
};

export { comparisonSettings } from './comparisonSettings';
